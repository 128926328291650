/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import React from 'react'
import { graphql, Link  } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  PostCard,
} from "../components/elements"

const Tag = ({ data, pageContext }) => {
  /* const page = data.datoCmsAuthor
  
  const {
    breadcrumb: { crumbs },
  } = pageContext */

  return (
    <Layout>
      {/* <HelmetDatoCms seo={page.seoMetaTags} /> */}

      <Main>
        <Hero>
          
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            >
              <Styled.h1>title</Styled.h1>
              
            </Container>
            <Overlay sx={{ opacity: 1 }} />
          
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 10 }} />
        </Hero>

        {/* Content */}
        <Section id="content">
          <Container sx={{ px: "1em" }}>
            
            <Flex sx={{ flexWrap: "wrap", mx: [0, 0, -2], mt: 4 }}>
              <p>page</p>
            </Flex>
          </Container>
          
        </Section>
      </Main>
    </Layout>
  )
}

export default Tag

